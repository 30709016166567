function navToggle(){
	const body = document.querySelector('body');
	const hamburgers = document.querySelectorAll('.Hamburger');
	const hamburgerArr = Array.from(hamburgers);

	for( const burger of hamburgerArr ) {
		burger.addEventListener('click', function (event) {
			body.classList.toggle('Nav-open');
		});
	};
};

export default() => {
	navToggle();
}
